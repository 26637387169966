import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const interviewer = `${HOST}interviewer`

export default {
  getInterviewers(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(interviewer, params).then(response => {
      context.setInterviewers(response.data)
    }, () => {
    })
  },
  getCalendarInterviewers(context1, fromDate, toDate) {
    const context = context1
    let params = { start_date: fromDate, end_date: toDate }
    if (params !== {}) {
      params = { params: { start_date: fromDate, end_date: toDate } }
    } else {
      params = { params: {} }
    }
    axios.get(`${interviewer}/calendar`, params).then(response => {
      context.setInterviewers(response.data)
    }, () => {
    })
  },
  addInterviewer(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(interviewer, body).then(response => {
      context.$vs.loading.close()
      context.interviewerAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewerNotAdded(error.response.data.return_message)
    })
  },
  updateInterviewer(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${interviewer}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.interviewerUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewerNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${interviewer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.interviewerDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewerNotDeleted(error.response.data.return_message)
    })
  },
  getInterviewerById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${interviewer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setInterviewer(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewerNotFound(error.response.data.return_message)
    })
  },
  getAvailableTimeSloatsByInterviewerIdAndDate(context1, id, date, month, year) {
    const context = context1
    axios.get(`${interviewer}/${id}/${date}/${month}/${year}`).then(response => {
      context.setAvailableTimeSloats(response.data)
    }, error => {
      context.setAvailableTimeSloatsNotFound(error.response.data.return_message)
    })
  },

}
